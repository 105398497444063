import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import "./Footer.scss";
import Section from "../Section";

const Footer = () => {
    const externalLinks = () =>
        [
            {
                icon: faGithub,
                path: "https://github.com/Dylan-Oleary",
                aria: "Link to my GitHub",
            },
            {
                icon: faInstagram,
                path: "https://www.instagram.com/breakfastwithroxy/",
                aria: "Link to my Instagram",
            },
            {
                icon: faLinkedin,
                path: "https://www.linkedin.com/in/dylan-o-leary-907514150/",
                aria: "Link to my LinkedIn",
            },
        ].map((link, index) => (
            <a
                href={link.path}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-secondary"
                aria-label={link.aria}
            >
                <FontAwesomeIcon icon={link.icon} />
            </a>
        ));

    return (
        <Section margin="mt-16 mb-8 md:my-16">
            <div id="Footer" className="text-center text-muted">
                <div className="flex justify-between mx-auto mb-2">
                    {externalLinks()}
                </div>
                <p className="text-sm">
                    © {new Date().getFullYear()} Dylan O’Leary. No Rights
                    Reserved, knock yourself out!
                </p>
            </div>
        </Section>
    );
};

export default Footer;
